import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../../../env/APIManager.js";
import ClipLoader from "react-spinners/ClipLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import styles from "./Signin.module.css";
import { useDispatch } from "react-redux";
import { LOG_USER, CREATED_AT } from "../../../../store/user.js";
import { useFetch } from "../../../../useFetch.js";


export default function Signin() {
	const navigator = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const { fetchFuntion, fetchLoading } = useFetch();

	const handleSignin = async () => {
		// setLoading(true);
		let valid = true;


		if (!validator.isEmail(email)) {
			toast.error("Email needs to be in a valid format.", { theme: "colored" });
			valid = false;
			return
		}
		if (password?.trim()?.length < 6) {
			toast.error("password is needs to be 6 characters long.", {
				theme: "colored",
			});
			valid = false;
			return
		}
		if (valid) {
			// const res = await userMerchantLogin({ email: email, password: password });
			let arg = {
				url: loginUser,
				method: "POST",
				data: { email: email, password: password },
			};

			const res = await fetchFuntion(arg);

			if (res?.code === "SUCCESS") {
				localStorage.setItem("token", JSON.stringify(res?.user?.token));
				dispatch(LOG_USER(true));

				dispatch(CREATED_AT(res?.user?.createdAt));
				navigator("/");
			}
		}
		// setLoading(false);
	};
	const onChangeEmail = (e) => {
		setEmail(e.target.value);
	};
	const onChangePassword = (e) => {
		setPassword(e.target.value.trim());
	};
	const SignupOnClickHandler = (e) => {
		navigator("../signup");
	};
	const handleForgotPassword = (e) => {
		navigator("../forgot-password");
	};
	return (
		<>
			<ToastContainer />
			{fetchLoading ? (
				<div className={styles["loading"]}>
					<ClipLoader size={100} color="#000" />
				</div>
			) : (
				<div className={styles["Main-Container"]}>

					<div className={styles["Body-Container"]}>
						<div className={styles["title"]}>
							<h1 className={styles["title-h1"]}>Log In</h1>
						</div>
						<div className={styles["Body-Container-Wrap"]}>
							<div className={styles["Body-Container-Wrap-Email-input"]}>
								<div className={styles["Body-Container-Wrap-label-div"]}>
									<span>Email</span>
									<span>Required</span>
								</div>
								<input
									className={styles["Body-Container-Wrap-input-email-signin"]}
									required
									id="standard-text"
									value={email}
									maxLength="50"
									variant="filled"
									type={"alpha"}
									name="email"
									size="small"
									onChange={onChangeEmail}
								// onKeyDown={(event) => {
								// 	if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
								// 		event.preventDefault();
								// 	}
								// }}
								/>
							</div>


							<div className={styles["Body-Container-Wrap-Password-input"]}>
								<div className={styles["Body-Container-Wrap-label-div"]}>
									<span>Password</span>
									<span>Required</span>
								</div>
								<input
									className={styles["Body-Container-Wrap-input-nonname-signin"]}
									required
									id="standard-text"
									label={`Password`}
									value={password}
									maxLength="50"
									variant="standard"
									type={"password"}
									name="password"
									onChange={onChangePassword}
								/>
							</div>

							<button
								className={styles["signup-button"]}
								onClick={handleSignin}
							>
								Log In
							</button>
							<button
								className={styles["ForgotPassword-button"]}
								onClick={handleForgotPassword}
							>
								Forgot Password?
							</button>
						</div>
						<div className={styles["text-to-login"]}>
							<span className={styles["texnbt-to-login-text"]}>
								New to Gesture For Vendors?
							</span>
							<span
								className={styles["text-to-login-button"]}
								onClick={SignupOnClickHandler}
							>
								Sign Up
							</span>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
