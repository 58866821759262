import { useState, useEffect } from "react";
import styles from "./ResetPassword.module.css";
import { useNavigate } from "react-router-dom";
import {
  resetPassword,
} from "../../../env/APIManager.js";
import ClipLoader from "react-spinners/ClipLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFetch } from "../../../useFetch";

const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z]+$/;

export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  const { fetchFuntion, fetchLoading } = useFetch();

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    setToken(params.get("tokenId"));
    setEmail(params.get("email"));
  }, []);
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const resetPasswordOnClickHandler = async () => {

    let arg = {
      url: resetPassword,
      method: "POST",
      data: {
        token: token,
        password: password,
      },
    };

    // const res = await ResetPasswordFromLink(token, password);

    if (password.length < 6) {
      toast.error("Password is required to have at least 6 characters.", {
        theme: "colored",
      });
      return
    }

    const res = await fetchFuntion(arg);
    if (res?.code === "SUCCESS") {
      toast.success(res?.data?.message, { theme: "colored" });
      setTimeout(() => navigate("/login"), 5000);
    } 

  };
  const loginOnClickHandler = () => {
    navigate("/login");
  };
  return (
    <>
      <ToastContainer />

      {fetchLoading ? (
        <div className={styles["loading"]}>
          <ClipLoader size={100} color="#000" />
        </div>
      ) : (
        <div className={styles["Main-Container"]}>
          <div className={styles["Body-Container"]}>
            <div className={styles["title"]}>
              <h1 className={styles["title-h1"]}>Reset Password</h1>
            </div>
            <div className={styles["Body-Container-Wrap"]}>
              <div className={styles["Body-Container-Wrap-Email-input"]}>
                <div className={styles["Body-Container-Wrap-label-div"]}>
                  <span>Email</span>
                  <span>Required</span>
                </div>
                <input
                  className={styles["Body-Container-Wrap-input-nonname"]}
                  disabled
                  id="standard-text"
                  label={`Email`}
                  value={email}
                  maxLength="50"
                  variant="standard"
                  type={"alpha"}
                  name="email"
                  // helperText={"Email Required"}
                  onKeyDown={(event) => {
                    if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>

              <div className={styles["Body-Container-Wrap-Password-input"]}>
                <div className={styles["Body-Container-Wrap-label-div"]}>
                  <span>Password</span>
                  <span>Required</span>
                </div>

                <input
                  className={styles["Body-Container-Wrap-input-nonname"]}
                  required
                  id="standard-text"
                  label={`Password`}
                  value={password}
                  maxLength="50"
                  variant="standard"
                  type={"password"}
                  name="password"
                  onChange={onChangePassword}
                />
              </div>

              <button
                className={styles["updatePassword-button"]}
                onClick={resetPasswordOnClickHandler}
              >
                Update Password
              </button>
            </div>
            <div className={styles["text-to-login"]}>
              <span className={styles["text-to-login-text"]}>
                Back to{" "}
                <span
                  className={styles["text-to-login-button"]}
                  onClick={loginOnClickHandler}
                >
                  Log In
                </span>
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
