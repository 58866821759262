import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./Settings.module.css";

import { v } from "../../../env/APIManager";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getUserUrl, paymentsSetupUrl } from "../../../env/APIManager";
import ClipLoader from "react-spinners/ClipLoader";
import CheckIcon from "@mui/icons-material/Check";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { links } from "./links";
import { useFetch } from "../../../useFetch";
import { useDispatch } from "react-redux";

import { LOGOUT_USER } from "../../../store/user";

function Settings() {
	const dispatch = useDispatch();
	

	let arg = {
		url: getUserUrl,
		method: "GET",
		auth: true,
	};

	const { result, loading, fetchFuntion, fetchLoading } = useFetch(arg);

	let paymentIsSetUp = false;
	if (
		result?.user?.paymentsSetup &&
		result?.user?.paymentsSetup !== null &&
		result?.user?.paymentsSetup !== undefined
	) {
		paymentIsSetUp = true;
	} else {
		paymentIsSetUp = false;
	}
	const navigate = useNavigate();

	// handle back arrow
	const handleGoBack = () => {
		navigate("/");
	};

	// function to open payment url

	const handleOpenUrl = async () => {
		if (loading) {
			toast.info("Loading...", { theme: "colored" });
			return;
		}
		const data = await fetchFuntion({
			url: paymentsSetupUrl,
			method: "GET",
			auth:true
		});

		if (data?.data?.url) window.location.replace(data?.data?.url);
	};

	// log out the user

	const handleLogout = () => {
		localStorage.removeItem("token");
		dispatch(LOGOUT_USER(false));
		navigate("../login");
	};
	return (
		<div className={styles.mainContainer}>
			<ToastContainer />
			<div className={styles.headerContainer}>
				<ArrowBackIcon
					fontSize="large"
					style={{ marginLeft: "-5px", cursor: "pointer" }}
					onClick={handleGoBack}
				/>
				<div className={styles.title}>Settings</div>
			</div>

			<div className={styles.mainContentContainer}>
				<div className={styles.contentContainer}>
					{links?.map((item) => {
						return item.id === "banking-information" ? (
							paymentIsSetUp ? (
								<div key={item.id} className={styles.cell2}>
									<div className={styles.cellHeader}>
										<div>{item.icon}</div>
										<div className={styles.cellTitle}>{item.title}</div>
										{loading ? <ClipLoader size={20} color="#000" /> : null}
										{!loading ? (
											<CheckIcon size={25} style={{ color: "#006A4E" }} />
										) : null}
									</div>
								</div>
							) : (
								<div
									onClick={handleOpenUrl}
									key={item.id}
									className={styles.cell}
								>
									<div className={styles.cellHeader}>
										<div>{item.icon}</div>
										<div className={styles.cellTitle}>{item.title}</div>
									</div>

									{fetchLoading || loading ? (
										<ClipLoader size={20} color="#000" />
									) : (
										<ArrowForwardIosIcon />
									)}
								</div>
							)
						) : (
							<Link to={item.link} className={styles.cell} key={item.id}>
								<div key={item.id} className={styles.cellHeader}>
									<div>{item.icon}</div>
									<div className={styles.cellTitle}>{item.title}</div>
								</div>
								<ArrowForwardIosIcon />
							</Link>
						);
					})}
				</div>
				<div className={styles.btnContainer}>
					<div className={styles.btnColumn}>
						<button onClick={handleLogout} className={styles.btn}>
							Log Out
						</button>
						<div>v. {v}</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Settings;
