import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DeleteModal from "./DeleteModal/DeleteModal";
import ClipLoader from "react-spinners/ClipLoader";
import styles from "../EditSpecialModal/EditSpecialModal.module.css";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { toast } from "react-toastify";
import { useFetch } from "../../../../useFetch";

import {
	updateSpecialHour,
	deleteSpecialHour,
} from "../../../../env/APIManager";

export default function EditSpecialModal({
	toggleEditSpecialHours,
	setSpecialHours,
	sortDays,
	hours,
	getHours,
	timeSlot,
	token,
	toastSettings,
	selectorStyle,
}) {
	const [slotId, setSlotId] = useState(null);
	const [selectedDay, setSelectedDay] = useState(null);
	const [openTime, setOpenTime] = useState("");
	const [closeTime, setCloseTime] = useState("");
	const [closedAllDay, setClosedAllDay] = useState(false);
	const [openAllDay, setOpenAllDay] = useState(false);
	const [disabledBefore, setDisabledBefore] = useState(null);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const { fetchFuntion } = useFetch();

	const handleSave = async () => {
		if (!selectedDay) {
			toast.error("Please select a date to proceed", {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
			return;
		}

		if (closedAllDay === false && openAllDay === false)
			if (openTime === "" || closeTime === "") {
				toast.error("Please select a time to proceed", {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				return;
			}

		const specialHour = {
			id: slotId,
			date: selectedDay?.getTime(),
			openTime: openTime,
			closeTime: closeTime,
			closedAllDay: closedAllDay,
			openAllDay: openAllDay,
		};

		// body: JSON.stringify({ specialHour: specialHour }),
		let arg = {
			url: updateSpecialHour,
			method: "POST",
			data: {
				specialHour: specialHour,
			},
			auth: true,
		};

		const response = await fetchFuntion(arg);

		setSaveLoading(true);
		if (response?.code === "SUCCESS") {
			setSpecialHours(sortDays(response?.specialHours));
			toast.success("Your changes have been saved", toastSettings);
			clearData();
			setTimeout(() => {
				toggleEditSpecialHours();
			}, 500);
		}
		setSaveLoading(false);
	};

	const handleDelete = async () => {
		setDeleteLoading(true);

		let arg = {
			url: deleteSpecialHour + timeSlot?.id,
			method: "DELETE",

			auth: true,
		};

		const response = await fetchFuntion(arg);
		// deleteSpecialHour(token, timeSlot?.id).then((response) => {
		if (response?.code === "SUCCESS") {
			setSpecialHours(sortDays(response?.specialHours));
			toast.success("Your changes have been saved", toastSettings);
			clearData();
			setTimeout(() => {
				toggleEditSpecialHours();
			}, 500);
		} 
		setDeleteLoading(false);
		// });
	};

	const clearData = () => {
		setSelectedDay(null);
		setOpenTime("");
		setCloseTime("");
		setClosedAllDay(false);
		setOpenAllDay(false);
	};

	const handleClosedCheck = (event) => {
		setClosedAllDay(event.target.checked);
		setOpenAllDay(false);
	};

	const handleOpenCheck = (event) => {
		setOpenAllDay(event.target.checked);
		setClosedAllDay(false);
	};

	const closeClick = async () => {
		toggleEditSpecialHours();
	};

	useEffect(() => {
		if (timeSlot) {
			setSlotId(timeSlot?.id);
			setSelectedDay(new Date(timeSlot?.date));
			setOpenTime(timeSlot?.openTime);
			setCloseTime(timeSlot?.closeTime);
			setClosedAllDay(timeSlot?.closedAllDay);
			setOpenAllDay(timeSlot?.openAllDay);
			setDisabledBefore([{ before: new Date() }]);
		}
	}, [timeSlot]);

	return (
		<>
			<div className={styles.whiteSpace}>
				<div className={styles.container}>
					<div className={styles.navbar}>
						<div className={styles.cancelBtn}>
							<CloseIcon
								onClick={closeClick}
								fontSize="large"
								sx={{ color: "black" }}
							/>
						</div>
						<div className={styles.title}>Edit Special Hours</div>
					</div>
					<div className={styles.calendarCard}>
						<DayPicker
							mode="single"
							defaultMonth={selectedDay}
							selected={selectedDay}
							onSelect={setSelectedDay}
							numberOfMonths={1}
							disabled={disabledBefore}
							styles={{ root: { "--rdp-background-color": "#F2EDFF" } }}
							modifiersStyles={{ selected: { backgroundColor: "#6C40FC" } }}
						/>
					</div>
					<div className={styles.selectTimeRow}>
						<div className={styles.time}>Time</div>
						{closedAllDay && (
							<div className={styles.selectorSpacer}>Closed All Day</div>
						)}
						{openAllDay && (
							<div className={styles.selectorSpacer}>Open All Day</div>
						)}
						{!closedAllDay && !openAllDay && (
							<div className={styles.timeSelectors}>
								<label
									className={[
										styles.customSelect,
										openTime === "" ? styles.placeholderSelected : "",
									].join(" ")}
								>
									<select
										value={openTime}
										onChange={(event) => setOpenTime(event.target.value)}
									>
										<option value="">Select a Time</option>
										{hours?.map((id, index) => (
											<option key={index} value={id}>
												{getHours(id)}
											</option>
										))}
									</select>
									{/* <ExpandMoreIcon style={selectorStyle} /> */}
								</label>
								<div style={{ fontSize: "10px" }}>—</div>
								<label
									className={[
										styles.customSelect,
										closeTime === "" ? styles.placeholderSelected : "",
									].join(" ")}
								>
									<select
										value={closeTime}
										onChange={(event) => setCloseTime(event.target.value)}
									>
										<option value="">Select a Time</option>
										{hours
											?.filter((el) => el >= openTime)
											?.map((id, index) => (
												<option key={index} value={id}>
													{getHours(id)}
												</option>
											))}
									</select>
									{/* <ExpandMoreIcon style={selectorStyle} /> */}
								</label>
							</div>
						)}
						<div id={styles.closeCheck}>
							<label className={styles.customCheckmark}>
								<input
									type="checkbox"
									checked={closedAllDay}
									onChange={(event) => {
										handleClosedCheck(event);
									}}
								/>
								<span className={styles.checkmark}></span>
								<span className={styles.label}>Closed All Day</span>
							</label>
						</div>
						<div id={styles.openCheck}>
							<label className={styles.customCheckmark}>
								<input
									type="checkbox"
									checked={openAllDay}
									onChange={(event) => {
										handleOpenCheck(event);
									}}
								/>
								<span className={styles.checkmark}></span>
								<span className={styles.label}>Open All Day</span>
							</label>
						</div>
					</div>
					<div
						className={[styles.btnStyle, styles.saveBtn].join(" ")}
						onClick={handleSave}
					>
						Save Changes
						{saveLoading && (
							<span>
								<ClipLoader color="#FFF" size={30} />
							</span>
						)}
					</div>
					<div
						className={[styles.btnStyle, styles.deleteBtn].join(" ")}
						onClick={() => setIsDeleteOpen(true)}
					>
						Delete
						{deleteLoading && (
							<span>
								<ClipLoader color="#FFF" size={30} />
							</span>
						)}
					</div>
				</div>
			</div>
			{isDeleteOpen && (
				<DeleteModal
					setIsDeleteOpen={setIsDeleteOpen}
					handleDelete={handleDelete}
				/>
			)}
		</>
	);
}
