import React, { useEffect, useRef, useState } from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	TimeScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";

import styles from "./Chart.module.css";
import "chartjs-adapter-date-fns";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	zoomPlugin,
	TimeScale
);

function Chart({ label, dataS, loading, updateChart, text, dataExists }) {
	const [labels, setLabels] = useState();
	const [revenue, setRevenue] = useState();
	const [, setDisplayReset] = useState(false);

	let myData = [];
	if (labels && revenue) {
		// if(labels?.length >1){
		labels?.forEach((element, index) => {
			let obj = {};
			obj["x"] = element;
			obj["y"] = revenue[index];
			myData.push(obj);
		});
		
	} else if (label && dataS) {
		let obj = {};
		label?.forEach((element, index) => {
			obj["x"] = element;
			obj["y"] = dataS[index];
			myData.push(obj);
		});
	}

	const data = {
		datasets: [
			{
				data: myData,
				backgroundColor: "#81c281",
			},
		],
	};

	let maxValue = myData?.map((item) => item?.y)?.sort((a, b) => b - a);

	function freezeAxis(scale) {
		scale.options.min = scale.min;
		if (maxValue) {
			scale.options.max = maxValue[0];
		}
	}

	function unfreezeAxis(scale) {
		scale.options.min = null; 
		if (maxValue) {
			scale.options.max = maxValue[0];
		}
	}

	// let stopPan = false;
	const options = {
		maintainAspectRatio: true,
		responsive: false,

		elements: {
			point: {
				radius: 0,
			},
			line: {
				borderWidth: 1.5,
			},
		},
		scales: {
			x: {
				type: "time",

				time: { unit: "day", tooltipFormat: "MM-dd-yyyy" },

				ticks: {
					color: "#000",
					size: 5,
					autoSkip: false,
				},
				title: {
					display: true,
					position: "nearest",
					text: "Dates are displayed in UTC",
					color: "#BDBDBD",
					font: {
						size: 14,
						weight: "bold",
					},
				},
			},
			y: {
				position: "left",
				title: {
					display: true,
					position: "nearest",
					text: text,
					color: "#BDBDBD",
					font: {
						size: 18,
						weight: "bold",
					},
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
			},
			zoom: {
				limits: {
					y: { min: "original", max: "original" },

					x: { min: "original", max: "original" },
				},
				pan: {
					enabled: true,
					mode: "x",
					speed: 1,

					onPanStart({ chart, point }) {
						if (chartRef) {
							chartRef?.current?.zoom(1.1);
							chartRef.current.scales.y.min = 0;
						}
						freezeAxis(chart.scales.y);
					},
					onPanComplete({ chart }) {
						unfreezeAxis(chart.scales.y);
					},
				},
				
			},
		},
	};

	const chartRef = useRef();

	useEffect(() => {
		if (chartRef) {
			setLabels(label);
			setRevenue(dataS);
			chartRef?.current?.update();
			setDisplayReset(false);
		}
	}, [updateChart]);

	return (
		<>
			{!loading && dataExists ? (
				<div>
					<Bar
						ref={chartRef}
						options={options}
						data={data}
						height={null}
						width={null}
						className={styles.chart}
					/>
				</div>
			) : (
				<div style={{ textAlign: "center" }}>
					Sorry, there is no available data to display. Please check back later.
				</div>
			)}
		</>
	);
}

export default Chart;
