import React, { useEffect, useState } from "react";
import styles from "./OrderHistory.module.css";
import FilterSelect from "./FilterSelect/FilterSelect";
import OrderDetails from "./OrderDetails/OrderDetails";
import TaskRow from "./TaskRow/TaskRow";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { TRIGGER_RELOAD_API } from "../../../store/app";
import { getAllTasks } from "../../../env/APIManager";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useFetch } from "../../../useFetch";

const filterEnum = {
	all: "all",
	new: "new",
	confirmed: "confirmed",
	ready: "ready",
	picked: "picked",
	delivered: "delivered",
	cancelled: "cancelled",
};

const toastSettings = {
	position: "top-right",
	autoClose: 5000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: "colored",
};

export default function OrderHistory({handleReloadPage }) {
	// const [loading, setLoading] = useState(true);
	const [tasks, setTasks] = useState([]);
	const [currentFilter, setCurrentFilter] = useState(filterEnum.all);
	const [focusedTaskId, setFocusedTaskId] = useState(null);
	const [isDetailOpen, setIsDetailOpen] = useState(false);
	const [loadMore, setLoadMore] = useState(true);
	const [pageToken, setPageToken] = useState(null);
	// const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
	const triggerReloadAPI = useSelector((state) => state?.app?.triggerReloadAPI);
	const dispatch = useDispatch();
	const optionsList = [
		"All",
		"New",
		"Confirmed",
		"Ready for Pick Up",
		"Picked",
		"Delivered",
		"Cancelled",
	];
	const [currentOption, setCurrentOption] = useState(optionsList[0]);
	const navigate = useNavigate();

	const handleStatus = (task) => {
		var msg;
		let date;
		if (
			task?.status === "new" ||
			((task?.status === "confirmed" || task?.status === "ready") &&
				task?.grunner_name === undefined)
		) {
			msg = "Due for pick up on…";
			date = task?.schedule_readable;
		} else if (
			(task?.status === "confirmed" || task?.status === "ready") &&
			task?.grunner_name
		) {
			msg = `${task?.grunner_name} is on the way to pick up...`;
			date = task?.schedule_readable;
		} else if (task?.status === "picked") {
			msg = `${task?.grunner_name} has picked up on`;
			date =
				new Date(task?.grunnerPickedTime)?.toLocaleDateString() +
				" " +
				new Date(task?.grunnerPickedTime)?.toLocaleTimeString([], {
					hour: "2-digit",
					minute: "2-digit",
				});
		} else if (task?.status === "delivered") {
			let datetime;
			if (task?.deliveredTime)
				datetime =
					new Date(task?.deliveredTime).toLocaleDateString() +
					" " +
					new Date(task?.deliveredTime).toLocaleTimeString([], {
						hour: "2-digit",
						minute: "2-digit",
					});
			msg = "Delivered on";
			date = datetime;
		} else if (task?.status === "cancelled") {
			let datetime;
			if (task?.cancelledTime) datetime = new Date(task?.cancelledTime);
			msg = `Cancelled on`;
			date = datetime && datetime.toLocaleDateString();
		}

		return { msg, readableDate: date };
	};

	const handleOpen = (task) => {
		setFocusedTaskId(task?.id);
		setIsDetailOpen(true);
	};

	const handleClose = () => {
		setFocusedTaskId(null);
		// handleReloadPage()
		// setTimeout(() => {
		setIsDetailOpen(false);
		// }, 100);
	};

	const handleGoBack = () => {
		navigate("/");
	};

	const handleFilterSelection = (index) => {
		setPageToken(null);
		setTasks([]);
		let option = optionsList[index];
		setCurrentOption(option);
		const formattedOption = option.toLowerCase().split(" ")[0];
		setCurrentFilter(formattedOption);
	};


	let apiUrl = getAllTasks;

	if (
		currentFilter !== undefined &&
		currentFilter !== null &&
		currentFilter !== "all"
	) {
		if (pageToken !== undefined && pageToken !== null) {
			apiUrl += `?statusFilter=${currentFilter}&pageToken=${pageToken}`;
		} else {
			apiUrl += `?statusFilter=${currentFilter}`;
		}
	} else {
		if (pageToken !== undefined && pageToken !== null) {
			apiUrl += `?pageToken=${pageToken}`;
		}
	}
	let arg = {
		url: apiUrl,
		method: "GET",
		auth: true,
	};
	var { result, loading, fetchFuntion, fetchLoading } = useFetch(arg);
	useEffect(() => {
		let arg = {
			url: getAllTasks+`?statusFilter=${currentFilter}`,
			method: "GET",
			auth: true,
		};

		let tasksList = fetchFuntion(arg);
		if (tasksList?.code === "SUCCESS") {
			setTasks(tasksList?.data?.tasks);
			dispatch(TRIGGER_RELOAD_API(false));
		}
	}, [triggerReloadAPI]);
	
	useEffect(() => {
		const getTasks = async () => {
			// result?.then((res) => {
				if (result?.code === "SUCCESS") {
					setTasks(result?.data?.tasks);
					dispatch(TRIGGER_RELOAD_API(false));
					if (result?.data?.hasMore !== undefined) {
						setLoadMore(result?.data?.hasMore);
					}
					if (result?.data?.pageToken !== undefined) {
						setPageToken(result?.data?.pageToken);
					}
				}
			// });
		};
		getTasks();
	}, []);

	useEffect(() => {
		handleFetchMore();
		dispatch(TRIGGER_RELOAD_API(false))
	}, [currentFilter]);

	const handleFetchMore = async () => {
		// setFetchMoreLoading(true);

		let taskList = await fetchFuntion(arg);

		if (taskList?.code === "SUCCESS") {
			if (taskList?.data?.tasks?.length > 0) {
				setTasks([...tasks, ...taskList?.data?.tasks]);
			}

			if (taskList?.data?.hasMore !== undefined) {
				setLoadMore(taskList?.data?.hasMore);
			}
			if (taskList?.data?.pageToken !== undefined) {
				setPageToken(taskList?.data?.pageToken);
			}
		}
		// setTimeout(() => {
		// 	setFetchMoreLoading(false);
		// }, 2000);
	};

	return (
		<>
			<ToastContainer {...toastSettings} />
			{isDetailOpen && (
				<OrderDetails
					taskId={focusedTaskId}
					handleStatus={handleStatus}
					handleClose={handleClose}
				/>
			)}
			<div className={styles.mainContainer}>
				{loading  ? (
					<div className={styles.clipLoader}>
						<ClipLoader color="#000" size={50} />
					</div>
				) : (
					<>
						<div className={styles.headerPosition}>
							<div className={styles.headerContainer}>
								<ArrowBackIcon
									fontSize="large"
									style={{ marginLeft: "-5px", cursor: "pointer" }}
									onClick={handleGoBack}
								/>
								<div className={styles.title}>Order History</div>
							</div>
							{/* {tasks?.length <= 0 ? null : ( */}
							<div className={styles.filterContainer}>
								<FilterSelect
									handleFilterSelection={handleFilterSelection}
									optionsList={optionsList}
									currentOption={currentOption}
								/>
							</div>
							{/* )} */}
						</div>
						{loading || fetchLoading ? <div className={styles.clipLoader}>
									<ClipLoader color="#000" size={50} />
								</div>:
						<div className={styles.mainContentContainer}>
							{tasks?.length <= 0 ? (
								<p style={{ padding: "15px", margin: "0 auto" }}>
									There are no orders to display.
								</p>
							) : (
								<>
									{tasks?.length <= 0 ? (
										<p style={{ padding: "15px", margin: "0 auto" }}>
											There are no{" "}
											{currentFilter === filterEnum.all ? null : currentFilter}{" "}
											orders to display.
										</p>
									) : (
										tasks?.map((task, index) => (
											<TaskRow
												task={task}
												key={index}
												handleStatus={handleStatus}
												handleOpen={handleOpen}
											/>
										))
									)}
								</>
							)}
						</div>
}

						<div className={styles.btnColumn}>
							{fetchLoading ? (
								<div className={styles.clipLoader}>
									<ClipLoader color="#000" size={50} />
								</div>
							) : loadMore ? (
								<button onClick={handleFetchMore} className={styles.btn}>
									Load More
								</button>
							) : null}
						</div>
					</>
				)}
			</div>
		</>
	);
}
