import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TRIGGER_RELOAD_API} from "../../store/app";
  import {getNewTasksUnacknowledged,postNewTasksAcknowledged} from "../../env/APIManager";
import styles from "./NewOrderNotificationModal.module.css";
import sound from "../../assets/sound.wav";
import { useFetch } from "../../useFetch";
import ClipLoader from "react-spinners/ClipLoader";


const NewOrderNotificationModal = () => {
  const navigate = useNavigate();

  const [newTasksNotAcknowledged, setNewTasksNotAcknowledged] = useState([]);
  const dispatch = useDispatch();
  let isLogged = useSelector((state) => state.user.isLogged);
  const [displayModal, setDisplayModal] = useState(false);
  const arg_getNewTasksUnacknowledged = {
    url: getNewTasksUnacknowledged,
    method: "GET",
    auth: true,
  };
  
  const { fetchFuntion,fetchLoading} = useFetch();
  const getNewTasks = async () => {
    let data = {};
    data = await fetchFuntion(arg_getNewTasksUnacknowledged);
    return data;
  };
  const getAllNewTasks = async () => {
    await getNewTasks().then((res) => {
      if (res?.code === "SUCCESS") {
        setNewTasksNotAcknowledged(res?.data?.tasks);
        if (res?.data?.tasks?.length > 0) {
          setDisplayModal(true);
        } else {
          setDisplayModal(false);
        }
      } else {
        setDisplayModal(false);
      }
    });
  };
  const pluck = (arr, key) => {
    return arr.map((i) => i[key]);
  };

  useEffect(() => {
    const audioRef = new Audio(sound);
    if (isLogged) {
      
      const intervalId = setInterval(async () => {
        await getAllNewTasks();
      }, 10000);
     
      if (displayModal) {
        audioRef.play();
        audioRef.loop = true;
      } else {
        audioRef.pause();
        audioRef.currentTime = 0;
      }
      return () => {
        clearInterval(intervalId);
        audioRef.pause();
        audioRef.currentTime = 0;
      };
    }
  }, [displayModal,isLogged]);
  const postingNewTasksAcknowledged = async () => {
    const arg_postNewTasksAcknowledged = {
      url: postNewTasksAcknowledged,
      method: "POST",
      auth: true,
      data: {
        taskIds: pluck(newTasksNotAcknowledged, "id"),
      },
    };
    let data = await fetchFuntion(arg_postNewTasksAcknowledged);
    return data;
  };

  const handleClick = async () => {
    setDisplayModal(false);
    const data = await postingNewTasksAcknowledged();
    if(data?.code==="SUCCESS"){

      dispatch(TRIGGER_RELOAD_API(true));
      navigate("./");

    }else{
      console.log("error");
    }
  };



  // const handleClick = () => {
  //   dispatch(SAVE_FILTER_ID("new"));
  //   dispatch(TRIGGER_RELOAD_API(true));
  //   setDisplayModal(false);
    
  //   // handleReloadPage();
  //   // setTimeout(() => {
  //     navigate("./");
  //   // }, 100);
  // };

  return (
    displayModal && (
      <div
        className={styles["container-notification-modal"]}
        onClick={handleClick}
      >
        <div className={styles["box-notification-modal"]}>
          <div>New Order</div>
          <div>Tap Anywhere to Proceed</div>
          {fetchLoading && <div className={styles.clipLoader}>
					    <ClipLoader color="#000" size={50} />
				    </div>}
        </div>
      </div>
    )
  );
};

export default NewOrderNotificationModal;
